<script>
import httpClient from "@/helpers/httpClient";
import {apiUrl} from "@/state/helpers";
import layoutProfile from "@/views/layouts/layoutProfile";
import {required} from "vuelidate/lib/validators";
import simplebar from "simplebar-vue";
import CheckSvg from "@/components/Svg/CheckSvg";
import Loader from "@/components/Partials/Loader.vue";
import VueRecaptcha from 'vue-recaptcha';

export default {
	name: "contactUs",
	components: {
		simplebar,
		layoutProfile,
		Loader,
		CheckSvg,
		VueRecaptcha
	},
	data() {
		return {
			submitted: false,
			isSentSuccess: false,
			isLoading: false,
			disabledSendBtn: true,
			form: {
				name: '',
				email: '',
				message: ''
			}
		}
	},
	validations: {
		form: {
			name: {required},
			email: {required},
			message: {required},
		}
	},
	methods: {
		verifyRecaptcha() {
			this.disabledSendBtn = false
		},
		sendContactForm() {
			this.submitted = true;
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			} else {
				this.isLoading = true;
				httpClient.post(apiUrl + '/api/v1/contact-us', this.form)
					.then((response) => {
						if (response.status === 200) {
							this.isSentSuccess = true;
							this.isLoading = false;
						}
					})
					.error((e) => {
						console.log("err", e)
					})
			}
		}
	}
}
</script>

<template>
	<layoutProfile>
		<h4 class="px-4 pt-4 mb-0">Contact Us</h4>
		<simplebar class="p-4 user-profile-desc">
			<Loader v-if="isLoading" />
			<div v-else class="card">
				<div class="card-header">
					<h5 class="font-size-15 mb-0">Need a super fast response? We'd love to hear from you!</h5>
				</div>
				<div class="card-body p-4">
					<div v-if="isSentSuccess" class="m-auto text-center">
						<CheckSvg class="mt-4"/>
						<h5 class="text-center mt-4">Thank you for contacting us. One of our support team members will contact you soon.</h5>
					</div>
					<form v-else @submit.prevent="sendContactForm">
						<b-row>
							<b-col lg="6" md="6" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">Name</label>
									<input
										type="text"
										class="form-control"
										id="updateName-input"
										:placeholder=" $t('chat.tabs.settings.modal.form.name.placeholder')"
										v-model="form.name"
										minlength="3"
										:class="{
                          'is-invalid': submitted && $v.form.name.$error,
                        }"
									/>
									<div
										v-if="submitted && $v.form.name.$error"
										class="invalid-feedback"
									>
                    <span v-if="!$v.form.name.required">{{
							$t("chat.tabs.settings.modal.form.name.validation.required")
						}}</span>
									</div>
								</div>
							</b-col>
							<b-col lg="6" md="6" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">Email</label>
									<input
										type="email"
										class="form-control"
										id="updateName-input"
										placeholder="Email"
										v-model="form.email"
										:class="{
                          'is-invalid': submitted && $v.form.email.$error,
                        }"
									/>
									<div
										v-if="submitted && $v.form.email.$error"
										class="invalid-feedback"
									>
										<span v-if="!$v.form.email.required">Please, provide valid data</span>
									</div>
								</div>
							</b-col>
							<b-col lg="12" md="12" sm="12">
								<div class="form-group mb-4">
									<label for="updateName-input">Message</label>
									<textarea
										type="text"
										class="form-control"
										id="updateName-input"
										rows="4"
										placeholder="Type your message here..."
										v-model="form.message"
										minlength="10"
										:class="{
                          'is-invalid': submitted && $v.form.message.$error,
                        }"
									/>
									<div
										v-if="submitted && $v.form.message.$error"
										class="invalid-feedback"
									>
										<span v-if="!$v.form.message.required">Please, provide valid data</span>
									</div>
								</div>
							</b-col>
						</b-row>
						<vue-recaptcha
							class="mb-2"
							sitekey="6Lcp27cpAAAAABMWCJXBViqGBITU2EqtqFEwYjhO"
							@verify="verifyRecaptcha"
							ref="recaptcha"
							:loadRecaptchaScript="true"
						></vue-recaptcha>
						<div>
							<b-button
								class="w-25 btn btn-primary btn-block"
								type="submit"
								:disabled="disabledSendBtn"
								variant="primary"
							>Submit</b-button>
						</div>
					</form>
				</div>
			</div>
		</simplebar>
	</layoutProfile>
</template>

<style scoped lang="scss">

</style>